import { Button, Col, Form, message, Progress, Row, Spin, Upload } from "antd";
import Title from "antd/lib/typography/Title";
import {
  stripeAddressChange,
  stripeIdentityChange,
  updateStripeStatus,
} from "../../redux/stripe/stripe.actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { GET_CAREGIVER_PROFILE } from "../../redux/profileDetails/profile.type";
import { setUser } from "../../redux/auth/auth.action";
import { useEffect, useState } from "react";

export default function StripeVerify(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const docsData = useSelector((state) => state?.stripeDocs);
  const token = localStorage.getItem("token");
  const accessToken = useSelector((state) => state.loginData.user.accessToken);
  const userData = useSelector((state) => state.loginData?.user);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (accessToken) {
      getUserData();
    }
  }, [accessToken]);

  const handleRedirection = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/create-stripe-link`,
        {
          returnUrl: window.location.href,
        },
        {
          headers: {
            accessToken: token,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      )
      .then((response) => {
        window.location.href = response?.data?.output?.url;
        // dispatch(updateStripeStatus("liveness"));
        console.log(response, "response");
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response?.data?.error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/get-caregiver-profile`,
        {},
        {
          headers: {
            accessToken: token,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      );
      //console.log('caregiver profile',response.data)
      if (response.data.status === 200) {
        console.log(response, "caregiverresponse");
        dispatch(updateStripeStatus(response.data.output?.stripeStatus));
        dispatch(
          setUser({
            ...userData,
            userDetails: { ...response.data.output },
            accessToken: accessToken,
          })
        );
        dispatch({ type: GET_CAREGIVER_PROFILE, payload: response.data });
      }
      return response;
    } catch (error) {
      console.log("caregiver profile", error);
    } finally {
      setLoading(false);
    }
  };

  const checkDisabled = () => {
    if (docsData?.stripeStatus?.liveness !== "verified") {
      return true;
    }
    return false;
  };
  console.log(props?.hideButton, "hideButton");
  return (
    <Row style={{ minWidth: "100%" }}>
      <Col xs={24} sm={24} lg={24} md={24}>
        <Spin spinning={loading}>
          {props?.hideButton ? (
            <>
              <h3 className="mb-3 color-secondary" style={{ fontSize: "28px" }}>
                Identity Verification
              </h3>
              <p className="color-secondary">
                MF Homecare partners with Stripe to safely deposit your earnings
                into your bank account. Complete the Liveness Verification to
                create your Stripe Payments Account for direct deposit{" "}
              </p>
              {docsData?.stripeStatus?.liveness === "pending" && (
                <>
                  <div className="d-flex align-items-center mt-3">
                    <h3
                      className="mb-0"
                      style={{ fontWeight: 700, marginLeft: "2%" }}
                    >
                      Liveness Verification:{" "}
                    </h3>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 p-2 color-secondary">
                        Verification Pending
                      </p>
                      <img
                        src="/images/icons/stripe-pending.svg"
                        alt="upload"
                        width={25}
                      />
                    </div>
                  </div>
                  {/* <p className="mb-0 p-2 color-secondary">
                    Liveness verification can only be performed by the owner of
                    the Connected Account by filling the form,{" "}
                    <Link to="" onClick={handleRedirection}>
                      Click here
                    </Link>{" "}
                    to fill your Connect Onboarding Form
                  </p> */}
                  <ol>
                    <li className="mb-0 p-2 color-secondary">
                      When you are redirected to Stripe, please select the
                      Liveness Verification method option{" "}
                    </li>
                    <li className="mb-0 p-2 color-secondary">
                      Upload the front and back of your photo ID* <br />{" "}
                      <a style={{ fontWeight: 600 }}>
                        *Acceptable IDs are driver’s license, passport or
                        official ID cards.{" "}
                        <span style={{ color: "red" }}>
                          Health cards are not accepted.{" "}
                        </span>
                      </a>{" "}
                    </li>

                    <li className="mb-0 p-2 color-secondary">
                      You will be prompted to allow camera access and take a
                      selfie{" "}
                    </li>

                    <li className="mb-0 p-2 color-secondary">
                      When completed, you will be redirected here to finish
                      signing up{" "}
                    </li>
                  </ol>
                  <Link
                    to=""
                    onClick={handleRedirection}
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    Click Here to Get Started
                  </Link>
                </>
              )}
              {docsData?.stripeStatus?.liveness == "verified" && (
                <>
                  <div className="d-flex align-items-center mt-3">
                    <h3
                      className="mb-0"
                      style={{ fontWeight: 700, marginLeft: "2%" }}
                    >
                      Liveness Verification:{" "}
                    </h3>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 p-2 color-secondary">
                        Verified Successfully
                      </p>
                      <img
                        src="/images/icons/stripe-verified.svg"
                        alt="upload"
                        width={25}
                      />
                    </div>
                  </div>
                  <ol>
                    <li className="mb-0 p-2 color-secondary">
                      When you are redirected to Stripe, please select the
                      Liveness Verification method option{" "}
                    </li>
                    <li className="mb-0 p-2 color-secondary">
                      Upload the front and back of your photo ID* <br />{" "}
                      <a style={{ fontWeight: 600 }}>
                        *Acceptable IDs are driver’s license, passport or
                        official ID cards.{" "}
                        <span style={{ color: "red" }}>
                          Health cards are not accepted.{" "}
                        </span>
                      </a>{" "}
                    </li>

                    <li className="mb-0 p-2 color-secondary">
                      You will be prompted to allow camera access and take a
                      selfie{" "}
                    </li>

                    <li className="mb-0 p-2 color-secondary">
                      When completed, you will be redirected here to finish
                      signing up{" "}
                    </li>
                  </ol>
                </>
              )}
              <div className="w-75"></div>
            </>
          ) : (
            <div
              className={
                "center-container min-vh-100 p-4 parent-avalability parent-avalability-custom"
              }
              style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
            >
              <div className={"welcome-container"} style={{ display: "Grid" }}>
                <div
                  style={{ padding: "30px" }}
                  className="availability-contents"
                >
                  <div className="mt-4 justify-content-center text-center heading-text">
                    <Title
                      className="mb-mob-0"
                      level={3}
                      style={{ fontWeight: "700", fontSize: "28px" }}
                    >
                      Identity Verification
                    </Title>
                    <p className="color-secondary">
                      MF Homecare partners with Stripe to safely deposit your
                      earnings into your bank account. Complete the Liveness
                      Verification to create your Stripe Payments Account for
                      direct deposit{" "}
                    </p>
                  </div>
                  {(docsData?.stripeStatus?.liveness === "not_uploaded" ||
                    docsData?.stripeStatus?.liveness === "pending") && (
                    <>
                      <div className="d-flex align-items-center mt-3">
                        <h3
                          className="mb-0 p-2"
                          style={{ fontWeight: 700, marginLeft: "2%" }}
                        >
                          Liveness Verification:{" "}
                        </h3>
                        {docsData?.stripeStatus?.liveness === "pending" && (
                          <div className="d-flex align-items-center">
                            <p className="mb-0 p-2 color-secondary">
                              Verification Pending
                            </p>
                            <img
                              src="/images/icons/stripe-pending.svg"
                              alt="upload"
                              width={25}
                            />
                          </div>
                        )}
                      </div>
                      {/* <p className="mb-0 p-2 color-secondary">
                        Liveness verification can only be performed by the owner
                        of the Connected Account by filling the form,{" "}
                        <Link to="" onClick={handleRedirection}>
                          Click here
                        </Link>{" "}
                        to fill your Connect Onboarding Form
                      </p> */}
                      <ol>
                        <li className="mb-0 p-2 color-secondary">
                          When you are redirected to Stripe, please select the
                          Liveness Verification method option{" "}
                        </li>
                        <li className="mb-0 p-2 color-secondary">
                          Upload the front and back of your photo ID* <br />{" "}
                          <a style={{ fontWeight: 600 }}>
                            *Acceptable IDs are driver’s license, passport or
                            official ID cards.{" "}
                            <span style={{ color: "red" }}>
                              Health cards are not accepted.{" "}
                            </span>
                          </a>{" "}
                        </li>

                        <li className="mb-0 p-2 color-secondary">
                          You will be prompted to allow camera access and take a
                          selfie{" "}
                        </li>

                        <li className="mb-0 p-2 color-secondary">
                          When completed, you will be redirected here to finish
                          signing up{" "}
                        </li>
                      </ol>
                      <Link
                        to=""
                        onClick={handleRedirection}
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          textAlign: "center",
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        Click Here to Get Started
                      </Link>
                    </>
                  )}
                  {docsData?.stripeStatus?.liveness == "verified" && (
                    <>
                      <div className="d-flex align-items-center mt-3">
                        <h3
                          className="mb-0 p-2"
                          style={{ fontWeight: 700, marginLeft: "2%" }}
                        >
                          Liveness Verification:{" "}
                        </h3>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 p-2 color-secondary">
                            Verified Successfully
                          </p>
                          <img
                            src="/images/icons/stripe-verified.svg"
                            alt="upload"
                            width={25}
                          />
                        </div>
                      </div>
                      <ol>
                        <li className="mb-0 p-2 color-secondary">
                          When you are redirected to Stripe, please select the
                          Liveness Verification method option{" "}
                        </li>
                        <li className="mb-0 p-2 color-secondary">
                          Upload the front and back of your photo ID* <br />{" "}
                          <a style={{ fontWeight: 600 }}>
                            *Acceptable IDs are driver’s license, passport or
                            official ID cards.{" "}
                            <span style={{ color: "red" }}>
                              Health cards are not accepted.{" "}
                            </span>
                          </a>{" "}
                        </li>

                        <li className="mb-0 p-2 color-secondary">
                          You will be prompted to allow camera access and take a
                          selfie{" "}
                        </li>

                        <li className="mb-0 p-2 color-secondary">
                          When completed, you will be redirected here to finish
                          signing up{" "}
                        </li>
                      </ol>
                    </>
                  )}
                  {!props?.hideButton && (
                    <div>
                      <div className="mt-3 mb-3 text-center">
                        <Button
                          className="get-started-btn"
                          onClick={() => {
                            if (
                              userData?.isProfileUpdated &&
                              userData?.isVerified
                            ) {
                              navigate("/caregiver/appointments-dashboard");
                            } else {
                              navigate("/caregiver/tutorials");
                            }
                          }}
                          shape="round"
                          disabled={
                            docsData?.stripeStatus?.liveness === "not_uploaded"
                          }
                          style={{
                            width: "260px",
                            height: "70px",
                            fontStyle: "bold",
                            fontSize: "20px",
                            backgroundColor: "#0F172A",
                            borderRadius: "45px",
                            color: "#fff",
                            ...(docsData?.stripeStatus?.liveness ===
                              "not_uploaded" && { opacity: 0.3 }),
                          }}
                        >
                          Finish Signing Up
                          <ArrowRightOutlined
                            type="Right"
                            style={{ color: "#fff" }}
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Spin>
      </Col>
    </Row>
  );
}
